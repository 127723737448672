import { FC, useCallback, useMemo, useState } from 'react';
import { Button } from '../ui/button';
import * as Sentry from '@sentry/nextjs';
import {
  readContract,
  sendTransaction,
  waitForTransactionReceipt,
} from '@wagmi/core';
import { NATIVE_TOKEN } from '@/config/config';
import {
  useAccount,
  useBalance,
  useChains,
  useConfig,
  useReadContracts,
  useSwitchChain,
  useWriteContract,
} from 'wagmi';
import { useAppSelector } from '@/hooks/useStore';
import {
  ContractFunctionExecutionError,
  erc20Abi,
  EstimateGasExecutionError,
  formatUnits,
  isAddressEqual,
  parseUnits,
  RpcRequestError,
  TransactionExecutionError,
  UserRejectedRequestError,
} from 'viem';
import { useToast } from '@/hooks/use-toast';
import { SquareArrowOutUpRight } from 'lucide-react';

export interface Data {
  estimation: Estimation;
}

export interface Estimation {
  tokenIn: Token;
  tokenOut: Token;
  slippage: number;
  recommendedSlippage: number;
  allowanceTarget: AllowanceTarget;
  bridgeFee: BridgeFees;
}

export interface BridgeFees {
  amount: string;
  token: string;
  usd: string;
  chain: string | number
}

export interface AllowanceTarget {
  from: string;
  to: string;
  data: string;
  value: string;
  gas: number;
  gasPrice: string;
}

export interface Token {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  amount: string;
  usd: string;
  minAmount?: string;
  approximateOperatingExpense?: string;
  approximateOperatingExpenseUsd?: string;
}

export const SwapButton: FC<{ data: Data | undefined }> = ({ data }) => {
  const { address, isConnected } = useAccount();
  const chain = useChains();
  const scans = useCallback(
    (chainId: number) => {
      const f = chain.find((i) => i.id === chainId);
      return f;
    },
    [chain]
  );
  const { srcToken } = useAppSelector((state) => state.swapSlice);
  const { inputAmount } = useAppSelector((state) => state.swapInputSlice);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const config = useConfig();
  const onError = useCallback(
    (error: Error) => {
      Sentry.captureException(error);
      setLoading(false);

      if (
        error instanceof UserRejectedRequestError ||
        error instanceof EstimateGasExecutionError ||
        error instanceof ContractFunctionExecutionError ||
        error instanceof TransactionExecutionError ||
        error instanceof RpcRequestError
      ) {
        toast({
          variant: 'destructive',
          title: error.name,
          description: error.shortMessage,
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem with your request.',
        });
      }
    },
    [toast]
  );
  const isNativeTokenSource = useMemo(
    () => srcToken?.address === NATIVE_TOKEN,
    [srcToken?.address]
  );
  const { data: erc_20, refetch: erc_20_refresh } = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: srcToken?.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [
          address as `0x${string}`,
          data?.estimation.allowanceTarget.to as `0x${string}`,
        ],
        chainId: srcToken?.chainId,
      },
      {
        address: srcToken?.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [address as `0x${string}`],
        chainId: srcToken?.chainId,
      },
      {
        address: srcToken?.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'decimals',
        args: [],
        chainId: srcToken?.chainId,
      },
    ],
    query: {
      enabled: !isNativeTokenSource,
    },
  });
  const onSuccess = useCallback(
    async (tx: any) => {
      const transactionReceipt = await waitForTransactionReceipt(config, {
        hash: tx,
        chainId: srcToken?.chainId,
      });
      setLoading(false);

      await erc_20_refresh();
      toast({
        variant: 'success',
        title: 'Transaction Approved',
        description: (
          <div className="flex flex-col gap-1">
            Transaction submitted successfully.
            <a
              className="font-semibold underline underline-offset-4 inline-flex items-center gap-1"
              href={`${
                scans(srcToken?.chainId as number)?.blockExplorers?.default.url
              }/tx/${transactionReceipt?.transactionHash}`}
              target="_blank"
              rel="noopener noreferrer">
              View on Explorer{' '}
              <sub>
                <SquareArrowOutUpRight className="w-3 h-3" />
              </sub>
            </a>
          </div>
        ),
      });
    },
    [config, erc_20_refresh, scans, srcToken?.chainId, toast]
  );
  const { switchChainAsync } = useSwitchChain({
    mutation: {
      onError,
    },
  });

  const { writeContractAsync } = useWriteContract({
    mutation: {
      onError,
      onSuccess,
    },
  });

  const { data: nativeTokenBalance } = useBalance({
    address,
    chainId: srcToken?.chainId,
    query: {
      enabled: !!address,
    },
  });

  // Determine if approval is required based on the allowance and input amount
  const isApprovalRequired = useMemo(() => {
    if (!erc_20) return false;

    const formattedAmount = Number(formatUnits(erc_20[0], erc_20[2]));
    const inputAmt = Number(inputAmount ?? 0);

    return formattedAmount <= inputAmt;
  }, [erc_20, inputAmount]);

  const balanceState = useMemo(() => {
    if (!isNativeTokenSource) {
      return 'SUCCESS';
    }
    if (typeof nativeTokenBalance === 'undefined' || inputAmount === '')
      return 'IDLE';
    if (
      parseUnits(inputAmount, nativeTokenBalance.decimals) >
      nativeTokenBalance.value
    )
      return 'INSUFFICIENT';
    return 'SUCCESS';
  }, [isNativeTokenSource, nativeTokenBalance, inputAmount]);

  const handleApprove = useCallback(async () => {
    if (!data || !srcToken) return;
    try {
      setLoading(true);
      await switchChainAsync({
        chainId: srcToken?.chainId,
      });
      const result = await readContract(config, {
        abi: erc20Abi,
        address: srcToken?.address as `0x${string}`,
        functionName: 'totalSupply',
        chainId: srcToken?.chainId,
      });
      await writeContractAsync({
        address: srcToken?.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'approve',
        args: [data.estimation.allowanceTarget.to as `0x${string}`, result],
      });

      // Mark token as approved
    } catch {}
  }, [data, srcToken, switchChainAsync, config, writeContractAsync]);

  const handleSwap = useCallback(async () => {
    if (!data) return; // Swap only if approved
    if (
      !isAddressEqual(
        data.estimation.allowanceTarget.from as `0x${string}`,
        address as `0x${string}`
      )
    )
      return;
    try {
      setLoading(true);
      await switchChainAsync({ chainId: srcToken?.chainId as number });

      const tx = await sendTransaction(config, {
        to: data.estimation.allowanceTarget.to as `0x${string}`,
        data: data.estimation.allowanceTarget.data as `0x${string}`,
        value: BigInt(data.estimation.allowanceTarget.value),
        chainId: srcToken?.chainId,
      });
      onSuccess(tx);
    } catch (e: any) {
      onError(e);
    }
  }, [
    address,
    config,
    data,
    onError,
    onSuccess,
    srcToken?.chainId,
    switchChainAsync,
  ]);

  const buttonState = useMemo(() => {
    if (!isConnected) return 'CONNECT';
    if (balanceState === 'IDLE') return 'ENTER_AMOUNT';
    if (balanceState === 'INSUFFICIENT') return 'INSUFFICIENT';
    if (!isNativeTokenSource && isApprovalRequired) return 'APPROVE';
    if (balanceState === 'SUCCESS' && !isApprovalRequired) return 'SWAP';
    return 'SWAP';
  }, [isConnected, balanceState, isNativeTokenSource, isApprovalRequired]);

  const buttonText = useMemo(() => {
    if (loading) return 'Processing...';
    switch (buttonState) {
      case 'CONNECT':
        return 'Connect Wallet';
      case 'ENTER_AMOUNT':
        return 'Enter Amount';
      case 'INSUFFICIENT':
        return 'Insufficient Balance';
      case 'APPROVE':
        return 'Approve';
      case 'SWAP':
        return 'Swap';
      default:
        return 'Swap';
    }
  }, [loading, buttonState]);

  const isButtonDisabled = useMemo(() => {
    if (loading) return true;
    if (
      buttonState === 'CONNECT' ||
      buttonState === 'ENTER_AMOUNT' ||
      buttonState === 'INSUFFICIENT' ||
      inputAmount === '' ||
      !data
    )
      return true;
    return false;
  }, [loading, buttonState, inputAmount, data]);

  const handleButtonClick = useCallback(async () => {
    if (!isConnected) {
      // Trigger wallet connection
      console.log('Connect wallet');
      return;
    }
    if (buttonState === 'APPROVE') {
      await handleApprove();
    } else if (buttonState === 'SWAP') {
      await handleSwap();
    }
  }, [isConnected, buttonState, handleApprove, handleSwap]);

  return (
    <Button
      onClick={handleButtonClick}
      disabled={isButtonDisabled}
      loading={loading}
      className="w-full mt-6 bg-gradient-to-r from-[#ff6b00] to-[#ff3b3b] text-white disabled:pointer-events-none">
      {buttonText}
    </Button>
  );
};
