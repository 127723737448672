import { useSwapProvider } from '@/providers/swap-provider';
import { useAppDispatch } from './useStore';
import { swapTokens } from '@/store/slices/swapSlice';
import { store } from '@/store';

export const useSwapTokens = () => {
  const dispatch = useAppDispatch();
  const { mutate } = useSwapProvider();

  const handleSwapTokens = () => {
    // Access the updated state after the swap
    dispatch(swapTokens());
    const state = store.getState().swapSlice;
    // Update the context
    if (state.srcToken && state.destToken) {
      mutate.setSrc(state.srcToken.address, state.srcToken.chainId);
      mutate.setDest(state.destToken.address, state.destToken.chainId);
    }
  };

  return handleSwapTokens;
};
